<template>
  <div id="exchangeApply">
    <header-nav :content="`${$t('pages_router_016')} / ${ $t('pages_exchange_001')}`" />
    <div class="common-card-body">
      <div class="exchange-header flex-align">
        <div class="sell-area flex-align">
          <span class="title">{{ $t('pages_exchange_003') }}</span>
          <span v-if="sellBalance !== null" class="balance">{{ $t('pages_exchange_004') }}: {{ sellBalance }}</span>
        </div>
        <div class="split-area" />
        <div class="buy-area flex-align">
          <span class="title">{{ $t('pages_exchange_002') }}</span>
          <span v-if="buyBalance !== null" class="balance">{{ $t('pages_exchange_004') }}: {{ buyBalance }}</span>
        </div>
      </div>
      <a-form
        ref="exchangeRef"
        class="ant-round-form exchange-form"
        label-align="left"
        :model="exchangeForm"
        :rules="exchangeRules"
        :label-col="{span: $i18n.locale === 'en' ? 5 : 3}"
      >
        <div class="exchange-select flex-align">
          <div class="sell-area">
            <a-form-item name="sellAmount">
              <number-input
                v-model:input-num="exchangeForm.sellAmount"
                input-size="large"
                :placeholder="$t('pages_exchange_006')"
                @update="sellAmountChange"
              />
              <a-form-item-rest>
                <a-select
                  v-model:value="exchangeForm.sellCurrency"
                  size="large"
                  option-filter-prop="label"
                  class="area-select"
                  :bordered="false"
                  @change="sellCurChange"
                >
                  <template #suffixIcon>
                    <img src="~@/assets/icons/ic-choose-active.png" class="ic-select">
                  </template>
                  <a-select-option
                    v-for="item in sellCurList"
                    :key="item.currency"
                    :value="item.currency"
                    :label="`${item.currency} - ${item.name}`"
                  >
                    <img :src="getCountryLogo(item.currency)" class="select-img-country">
                    <span>{{ item.currency }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item-rest>
            </a-form-item>
          </div>
          <div class="split-area" style="margin-bottom: 24px" @click="curExchange">
            <img src="~@/assets/icons/ic-exchange.png">
          </div>
          <div class="buy-area">
            <a-form-item name="buyAmount">
              <number-input
                v-model:input-num="exchangeForm.buyAmount"
                input-size="large"
                :placeholder="$t('pages_exchange_007')"
                @update="buyAmountChange"
              />
              <a-form-item-rest>
                <a-select
                  v-model:value="exchangeForm.buyCurrency"
                  size="large"
                  option-filter-prop="label"
                  class="area-select"
                  :bordered="false"
                  @change="buyCurChange"
                >
                  <template #suffixIcon>
                    <img src="~@/assets/icons/ic-choose-active.png" class="ic-select">
                  </template>
                  <a-select-option
                    v-for="item in buyCurList"
                    :key="item.currency"
                    :value="item.currency"
                    :label="`${item.currency} - ${item.name}`"
                  >
                    <img :src="getCountryLogo(item.currency)" class="select-img-country">
                    <span>{{ item.currency }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item-rest>
            </a-form-item>
          </div>
        </div>
        <div v-if="exchangeForm.lockRateType !== '40'" class="exchange-rate flex-align">
          <a-spin v-if="loading" :tip="$t('pages_exchange_030')" />
          <div v-else-if="exchangeForm.rate" class="rate-info flex-align">
            <img src="~@/assets/icons/ic-great.png">
            <div class="rate flex-align">
              <span>{{ $t('pages_exchange_028') }}</span>
              <span style="margin-left: 3px">{{ exchangeForm.rate }}</span>
            </div>
            <div class="update-date">
              {{ $t('pages_exchange_029') }} {{ queryDate }}
            </div>
          </div>
          <div v-else class="rate-error">{{ resMsg }}</div>
          <div v-if="!loading && exchangeForm.sellCurrency && exchangeForm.buyCurrency" class="refresh-process">
            <count-down
              :valid-time="validTime"
              :trade-currency="`${exchangeForm.sellCurrency}${exchangeForm.buyCurrency}`"
              @refresh="loadExchangeRate"
            />
          </div>
        </div>

        <div v-if="showLockRate" class="exchange-type">
          <div class="header">{{ $t('pages_exchange_012') }}</div>
          <a-tabs v-model:activeKey="exchangeForm.lockRateType" destroy-inactive-tab-pane class="exchange-tabs" @change="tabChange">
            <a-tab-pane key="00">
              <template #tab>
                <a-tooltip :title="$t('pages_exchange_008')">{{ $t('pages_exchange_013') }}</a-tooltip>
              </template>
              {{ $t('pages_exchange_008') }}
            </a-tab-pane>
            <a-tab-pane v-if="t0.status !== 2" key="10" :disabled="t0.status === 1">
              <template #tab>
                <a-tooltip :title="$t('pages_exchange_009')">{{ t0.tabTitle }}</a-tooltip>
              </template>
              {{ $t('pages_exchange_009') }}
            </a-tab-pane>
            <a-tab-pane v-if="t1.status !== 2" key="20" :disabled="t1.status === 1">
              <template #tab>
                <a-tooltip :title="$t('pages_exchange_010')">{{ t1.tabTitle }}</a-tooltip>
              </template>
              {{ $t('pages_exchange_010') }}
            </a-tab-pane>
            <a-tab-pane v-if="t2.status !== 2" key="30" :disabled="t2.status === 1">
              <template #tab>
                <a-tooltip :title="$t('pages_exchange_011')">{{ t2.tabTitle }}</a-tooltip>
              </template>
              {{ $t('pages_exchange_011') }}
            </a-tab-pane>
            <a-tab-pane v-if="getUsedCheck(pageLabs, 140101)" key="40">
              <template #tab>
                <a-tooltip :title="$t('pages_exchange_068')">{{ $t('pages_exchange_061') }}</a-tooltip>
              </template>
              {{ $t('pages_exchange_068') }}
              <a-form-item name="entrustRate" style="margin-top: 24px; position: relative" :label="$t('pages_exchange_063')">
                <a-input-number
                  v-model:value="exchangeForm.entrustRate"
                  style="width: 248px"
                  :min="0"
                  :placeholder="$t('pages_exchange_062')"
                  @change="rateChange"
                />
                <div v-if="showTip" style="margin-top: 1px; color: #ff4d4f">{{ $t('pages_exchange_065') }}</div>
                <div class="exchange-rate flex-align" style="position: absolute; top: 5px; left: 260px">
                  <a-spin v-if="loading" size="small" />
                  <div v-else-if="exchangeForm.rate" class="rate-info flex-align">
                    <img src="~@/assets/icons/ic-great.png" style="margin: -4px -5px 0 0; width: 20px; height: 20px">
                    <div class="rate flex-align" style="font-size: 13px; color: #000">
                      <span>{{ $t('pages_exchange_078') }}</span>
                      <span style="margin-left: 3px">{{ exchangeForm.rate }}</span>
                    </div>
                    <div class="update-date">
                      {{ $t('pages_exchange_029') }} {{ queryDate }}
                    </div>
                  </div>
                  <div v-else class="rate-error">{{ resMsg }}</div>
                  <div v-if="!loading && exchangeForm.sellCurrency && exchangeForm.buyCurrency" class="refresh-process">
                    <count-down
                      :valid-time="validTime"
                      :trade-currency="`${exchangeForm.sellCurrency}${exchangeForm.buyCurrency}`"
                      @refresh="loadExchangeRate"
                    />
                  </div>
                </div>
              </a-form-item>
              <a-form-item name="deadLineDate" :label="$t('pages_exchange_064')">
                <a-date-picker
                  v-model:value="exchangeForm.deadLineDate"
                  style="width: 248px"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                />
                <span style="margin-left: 12px; font-size: 13px">{{ $t('pages_exchange_069') }}</span>
              </a-form-item>
              <div style="color: #ff4d4f; font-size: 13px">{{ $t('pages_exchange_080') }}</div>
            </a-tab-pane>
          </a-tabs>
          <div
            v-if="['10', '20', '30'].includes(exchangeForm.lockRateType)"
            class="lock-tip"
          >{{ $t('pages_exchange_082', [lockDate]) }}</div>
        </div>
      </a-form>

      <div class="exchange-footer" :style="{marginTop: exchangeForm.lockRateType === '40' ? '24px' : '88px'}">
        <a-checkbox v-if="canSubmit" v-model:checked="isAgree" style="width: 100%">{{ $t('pages_exchange_031') }}</a-checkbox>
        <div v-else class="no-explain">
          {{ $t('pages_exchange_045') }}{{ canTradeTime }}{{ $t('pages_exchange_046') }}
        </div>
        <div class="flex-align">
          <a-button
            shape="round"
            type="primary"
            class="submit-btn"
            :disabled="!(isAgree && canSubmit)"
            @click="exchangeSubmit"
          >{{ $t('pages_exchange_032') }}</a-button>
        </div>
      </div>
    </div>
    <apply-modal
      v-model:visible="visible"
      :is-tip="isTip"
      :exchange-info="exchangeForm"
      :theme-data="themeData"
      :arithmetic="arithmetic"
      @success="applySuccess"
    />
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import CountDown from '@/components/count-down'
import ApplyModal from './components/apply-modal'
import NumberInput from '@/components/number-input'
import { ref, reactive, toRefs, inject, computed } from 'vue'
import { _exchange, _account } from '@/api'
import { getCountryLogo, evalMathStr, parseMoney, getUsedCheck, customRound } from '@/util'
import { useStore } from 'vuex'
import moment from 'moment'
import i18n from '@/locale'
export default {
  name: 'Exchange',
  components: {
    'header-nav': HeaderNav,
    'count-down': CountDown,
    'apply-modal': ApplyModal,
    'number-input': NumberInput
  },
  setup() {
    const store = useStore()
    const exchangeRef = ref(null)
    const validateAmount = (rule, value) => {
      const { sellCurrency, buyCurrency } = state.exchangeForm
      if (rule.field === 'buyAmount') {
        if (!value) return Promise.reject(i18n.global.t('pages_exchange_023'))
        const areaArr = String(value).split('.')
        if (!Number.isFinite(+value)) {
          state.exchangeForm.sellAmount = null
          return Promise.reject(i18n.global.t('pages_exchange_024'))
        }
        if (areaArr[0].length > 16) {
          return Promise.reject(i18n.global.t('pages_exchange_020'))
        }
        if (buyCurrency === 'JPY' && areaArr.length > 1) {
          return Promise.reject(i18n.global.t('pages_exchange_026'))
        }
        if (areaArr.length > 1 && areaArr[1].length > 2) {
          return Promise.reject(i18n.global.t('pages_exchange_027'))
        }
      } else {
        if (!value) return Promise.reject(i18n.global.t('pages_exchange_018'))
        const areaArr = String(value).split('.')
        if (!Number.isFinite(+value)) {
          state.exchangeForm.buyAmount = null
          return Promise.reject(i18n.global.t('pages_exchange_019'))
        }
        if (areaArr[0].length > 16) {
          return Promise.reject(i18n.global.t('pages_exchange_025'))
        }
        if (sellCurrency === 'JPY' && areaArr.length > 1) {
          return Promise.reject(i18n.global.t('pages_exchange_021'))
        }
        if (areaArr.length > 1 && areaArr[1].length > 2) {
          return Promise.reject(i18n.global.t('pages_exchange_022'))
        }
        if (+value < 10) return Promise.reject(i18n.global.t('pages_exchange_033'))
      }
      return Promise.resolve()
    }
    const validateERate = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_exchange_067'))
      return Promise.resolve()
    }

    const state = reactive({
      themeData: inject('$themeData'),
      loading: true,
      visible: false,
      canSubmit: true,
      showTip: false,
      isTip: false,
      canTradeTime: '',
      buyBalance: null,
      sellBalance: null,
      buyCurList: [],
      sellCurList: [],
      supList: [],
      pageLabs: [],
      exchangeForm: {
        buyCurrency: '',
        sellCurrency: '',
        buyAmount: null,
        sellAmount: null,
        rate: '',
        lockRateType: '00',
        sellDirection: 'SELL',
        entrustRate: null,
        deadLineDate: null
      },
      exchangeRules: {
        sellAmount: [{ required: true, validator: validateAmount }],
        buyAmount: [{ required: true, validator: validateAmount }],
        entrustRate: [{ required: true, validator: validateERate }],
        deadLineDate: [{ required: true, message: i18n.global.t('pages_exchange_066') }]
      },
      isAgree: false,
      showLockRate: false,
      t0: {},
      t1: {},
      t2: {},
      resMsg: '',
      validTime: null,
      arithmetic: '',
      queryDate: ''
    })

    state.lockDate = computed(() => {
      if (state.exchangeForm.lockRateType === '10') return state.t0.date
      if (state.exchangeForm.lockRateType === '20') return state.t1.date
      return state.t2.date
    })

    const disabledDate = (current) => {
      const startDay = moment().startOf('day')
      return current && (current < startDay || current > startDay.add(14, 'days'))
    }

    const tabChange = () => {
      loadSupRateCur()
    }

    const buyAmountChange = () => {
      state.exchangeForm.sellDirection = 'BUY'
      calcSellAmount()
      exchangeRef.value.validateFields('sellAmount')
    }

    const sellAmountChange = () => {
      state.exchangeForm.sellDirection = 'SELL'
      calcBuyAmount()
      exchangeRef.value.validateFields('buyAmount')
    }

    const rateChange = (e) => {
      state.showTip = false
      if (e) {
        calcTip(e)
        if (state.exchangeForm.sellDirection === 'SELL') sellAmountChange()
        else buyAmountChange()
      }
    }

    const buyCurChange = (currency) => {
      const { sellCurrency } = state.exchangeForm
      const bCurInfo = state.buyCurList.find(item => item.currency === currency)
      state.buyBalance = parseMoney(bCurInfo.amount, bCurInfo.currency)
      const canUseSellCurArr = state.supList.filter(item => item.buyCurrency === currency)
      const flag = canUseSellCurArr.some(item => item.sellCurrency === sellCurrency)
      if (!flag) state.exchangeForm.sellCurrency = canUseSellCurArr[0].sellCurrency
      const sCurInfo = state.sellCurList.find(item => item.currency === state.exchangeForm.sellCurrency)
      state.sellBalance = parseMoney(sCurInfo.amount, sCurInfo.currency)
      loadExchangeRate()
    }

    const sellCurChange = (currency) => {
      const { buyCurrency } = state.exchangeForm
      const curInfo = state.sellCurList.find(item => item.currency === currency)
      state.sellBalance = parseMoney(curInfo.amount, curInfo.currency)
      const canUseBuyCurArr = state.supList.filter(item => item.sellCurrency === currency)
      const flag = canUseBuyCurArr.some(item => item.buyCurrency === buyCurrency)
      if (!flag) state.exchangeForm.buyCurrency = canUseBuyCurArr[0].buyCurrency
      const bCurInfo = state.buyCurList.find(item => item.currency === state.exchangeForm.buyCurrency)
      state.buyBalance = parseMoney(bCurInfo.amount, bCurInfo.currency)
      loadExchangeRate()
    }

    const curExchange = () => {
      const { buyCurrency, sellCurrency } = state.exchangeForm
      const { sellBalance, buyBalance } = state
      state.sellBalance = buyBalance
      state.buyBalance = sellBalance
      state.exchangeForm.buyCurrency = sellCurrency
      state.exchangeForm.sellCurrency = buyCurrency
      loadExchangeRate()
    }

    const exchangeSubmit = () => {
      exchangeRef.value.validateFields()
        .then(() => {
          state.isTip = false
          if (['10', '20', '30'].includes(state.exchangeForm.lockRateType)) {
            const curInfo = state.sellCurList.find(item => item.currency === state.exchangeForm.sellCurrency)
            if (state.exchangeForm.sellAmount > curInfo.amount) state.isTip = true
          }
          state.visible = true
        })
        .catch(err => console.log(err))
    }

    const applySuccess = () => {
      state.visible = false
      state.isAgree = false
      state.exchangeForm = {
        buyCurrency: 'CNY',
        sellCurrency: '',
        buyAmount: null,
        sellAmount: null,
        rate: '',
        lockRateType: '00',
        sellDirection: 'SELL',
        entrustRate: null,
        deadLineDate: null
      }
      state.showTip = false
      initPage()
      store.dispatch('getCurList')
    }

    const loadTradeDate = async () => {
      const res = await _exchange.getTradeDate()
      if (res.data.resCode === '000000') {
        state.canSubmit = true
      } else {
        state.canSubmit = false
        state.canTradeTime = res.data.data
      }
    }

    const loadExchangeRate = async () => {
      state.loading = true
      state.exchangeForm.rate = ''
      state.arithmetic = ''
      state.queryDate = ''
      state.validTime = 12
      state.resMsg = ''
      const res = await _exchange.getExchangeRate({
        buyCurrency: state.exchangeForm.buyCurrency,
        sellCurrency: state.exchangeForm.sellCurrency
      })
      if (res.data.succ) {
        const { data } = res.data
        if (data.sellCurrency !== state.exchangeForm.sellCurrency || data.buyCurrency !== state.exchangeForm.buyCurrency) return
        state.exchangeForm.rate = data.rate
        state.arithmetic = data.arithmetic
        state.queryDate = data.queryDate
        state.validTime = data.validTime ? data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1) : 12
        if (state.exchangeForm.sellDirection === 'SELL') calcBuyAmount()
        else calcSellAmount()
        if (state.exchangeForm.lockRateType === '40' && state.exchangeForm.entrustRate) calcTip(state.exchangeForm.entrustRate)
      } else {
        state.resMsg = res.data.resMsg
        if (res.data.data) state.arithmetic = res.data.data.arithmetic
        state.exchangeForm.buyAmount = null
        state.exchangeForm.sellAmount = null
      }
      state.loading = false
      if (state.exchangeForm.lockRateType === '40') exchangeRef.value.validateFields('entrustRate')
    }

    const loadUserLockRateAuth = async () => {
      const res = await _exchange.getUserLockRateAuth({})
      if (res.data.succ) {
        const { data } = res.data
        const { t0, t1, t2, t0Date, t1Date, t2Date } = data
        if (!data || JSON.stringify(data) === '{}') {
          state.showLockRate = false
        } else {
          state.showLockRate = true
          state.t0.status = lockShowStatus(t0)
          state.t1.status = lockShowStatus(t1)
          state.t2.status = lockShowStatus(t2)
          state.t0.date = t0Date
          state.t1.date = t1Date
          state.t2.date = t2Date
          state.t0.tabTitle = t0Date ? `${i18n.global.t('pages_exchange_014')} (${t0Date}${i18n.global.t('pages_exchange_017')})` : `${i18n.global.t('pages_exchange_014')}`
          state.t1.tabTitle = t1Date ? `${i18n.global.t('pages_exchange_015')} (${t1Date}${i18n.global.t('pages_exchange_017')})` : `${i18n.global.t('pages_exchange_015')}`
          state.t2.tabTitle = t2Date ? `${i18n.global.t('pages_exchange_016')} (${t2Date}${i18n.global.t('pages_exchange_017')})` : `${i18n.global.t('pages_exchange_016')}`
        }
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1401, auth: 'Y' })
      if (res.data.succ) state.pageLabs = res.data.data || []
    }

    const loadSupRateCur = async () => {
      const res = await _exchange.getSupRateCur({
        lockRateType: state.exchangeForm.lockRateType
      })
      if (res.data.succ) {
        const buysMap = {}
        const sellsMap = {}
        const buyCurList = []
        const sellCurList = []
        const { infoList, supList } = res.data.data

        if (!supList || supList.length === 0) {
          state.loading = false
          state.resMsg = i18n.global.t('pages_exchange_005')
          return
        }

        if (supList) state.supList = supList

        supList.map(item => {
          buysMap[item.buyCurrency] = item
          sellsMap[item.sellCurrency] = item
        })

        for (const item of infoList) {
          if (buysMap[item.currency] && item.currency !== 'CAD') buyCurList.push(item)
          if (sellsMap[item.currency]) sellCurList.push(item)
        }

        state.buyCurList = buyCurList
        state.sellCurList = sellCurList

        const initSell = sellCurList.some(item => item.currency === (state.exchangeForm.sellCurrency || 'USD'))
        state.exchangeForm.sellCurrency = initSell ? (state.exchangeForm.sellCurrency || 'USD') : sellCurList[0].currency

        const buyCurArr = state.supList.filter(item => item.sellCurrency === state.exchangeForm.sellCurrency)
        const initBuy = buyCurArr.some(item => item.buyCurrency === 'CNY')
        state.exchangeForm.buyCurrency = initBuy ? 'CNY' : buyCurArr[0].buyCurrency

        const sellCurInfo = sellCurList.find(item => item.currency === state.exchangeForm.sellCurrency)
        state.sellBalance = parseMoney(sellCurInfo.amount, sellCurInfo.currency)

        const buyCurInfo = buyCurList.find(item => item.currency === state.exchangeForm.buyCurrency)
        state.buyBalance = parseMoney(buyCurInfo.amount, buyCurInfo.currency)

        loadExchangeRate()
      }
    }

    const calcTip = (entrustRate) => {
      state.showTip = false
      if (state.arithmetic === 'DIV') {
        if (entrustRate >= state.exchangeForm.rate) state.showTip = true
      } else {
        if (entrustRate <= state.exchangeForm.rate) state.showTip = true
      }
    }

    const calcBuyAmount = () => {
      const { sellAmount, buyCurrency, rate, entrustRate, lockRateType } = state.exchangeForm
      if (!state.arithmetic) return
      if (lockRateType !== '40' && !rate) return
      if (lockRateType === '40' && !entrustRate) return
      if (!sellAmount) {
        state.exchangeForm.buyAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(sellAmount)) && isFinite(sellAmount)))) return
      state.exchangeForm.buyAmount = calcAmount(sellAmount, buyCurrency, lockRateType === '40' ? entrustRate : rate)
    }

    const calcSellAmount = () => {
      const { buyAmount, sellCurrency, rate, entrustRate, lockRateType } = state.exchangeForm
      if (!state.arithmetic) return
      if (lockRateType !== '40' && !rate) return
      if (lockRateType === '40' && !entrustRate) return
      if (!buyAmount) {
        state.exchangeForm.sellAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(buyAmount)) && isFinite(buyAmount)))) return

      state.exchangeForm.sellAmount = calcAmount(buyAmount, sellCurrency, lockRateType === '40' ? entrustRate : rate)
    }

    const calcAmount = (targetAmount, targetCurrency, rate) => {
      let amount = null
      if (state.exchangeForm.sellDirection === 'SELL') {
        if (state.arithmetic === 'DIV') {
          if (targetCurrency === 'JPY') {
            amount = customRound((evalMathStr(targetAmount + '/' + rate) * 1))
          } else {
            amount = customRound((evalMathStr(targetAmount + '/' + rate) * 1) * 100) / 100
          }
        } else {
          if (targetCurrency === 'JPY') {
            amount = customRound((evalMathStr(targetAmount + '*' + rate) * 1))
          } else {
            amount = customRound((evalMathStr(targetAmount + '*' + rate) * 1) * 100) / 100
          }
        }
      } else {
        if (state.arithmetic === 'DIV') {
          if (targetCurrency === 'JPY') {
            amount = customRound((evalMathStr(targetAmount + '*' + rate) * 1))
          } else {
            amount = customRound((evalMathStr(targetAmount + '*' + rate) * 1) * 100) / 100
          }
        } else {
          if (targetCurrency === 'JPY') {
            amount = customRound((evalMathStr(targetAmount + '/' + rate) * 1))
          } else {
            amount = customRound((evalMathStr(targetAmount + '/' + rate) * 1) * 100) / 100
          }
        }
      }
      return amount
    }

    const lockShowStatus = (t) => { // 0-can use 1-show but disabled 2-hidden
      if (!t || t === '00') return 2
      if (t === '10') return 0
      if (t === '20') return 1
    }

    const initPage = () => {
      loadUserLockRateAuth()
      loadSupRateCur()
      loadTradeDate()
      loadChildrenMenu()
    }

    initPage()

    return {
      exchangeRef,
      getCountryLogo,
      getUsedCheck,
      disabledDate,
      tabChange,
      buyAmountChange,
      sellAmountChange,
      rateChange,
      buyCurChange,
      sellCurChange,
      curExchange,
      loadExchangeRate,
      exchangeSubmit,
      applySuccess,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#exchangeApply {
  text-align: left;
  .common-card-body {
    padding: 0 48px 18px 48px;

    .exchange-form:deep(.ant-form-item-explain-error) {
      white-space: normal;
    }
    .exchange-header, .exchange-select {
      margin-top: 18px;
      .sell-area, .buy-area {
        flex: 7;
        position: relative;
        overflow: hidden;
        padding: 2px;
        .title {
          font-size: 16px;
          font-weight: 500;
        }
        .balance {
          margin-left: 3px;
          color: #999;
        }
        .area-select {
          left: auto;
          right: 1px;
          top: 1px;
          bottom: 1px;
          width: 30%;
          .ic-select {
            width: 8px;
            margin-top: -4px;
          }
        }
        .ant-form-item-has-error {
          .area-select {
            left: auto !important;
            right: 1px !important;
          }
        }
      }
      .split-area {
        flex: 1;
        padding: 0 24px;
        text-align: center;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .exchange-rate {
      .rate-info {
        img {
          width: 25px;
          height: 25px;
          margin-top: -6px;
        }
        .rate {
          font-size: 16px;
          font-weight: 500;
          margin-left: 8px;
        }
        .update-date {
          font-size: 12px;
          color: #666;
          margin-left: 8px;
        }
      }
      .rate-error {
        color: @error-color;
      }
      .refresh-process {
        width: 20px;
        height: 20px;
        padding: 2px;
        overflow: hidden;
        margin-left: 5px;
      }
    }
    .exchange-type {
      margin-top: 36px;
      .header {
        font-size: 16px;
        font-weight: 500;
      }
      .exchange-tabs {
        margin-top: 12px;
      }
      .exchange-tabs:deep(.ant-tabs-content-holder) {
        margin-top: 8px;
        color: #999;
      }
      .lock-tip {
        color: @error-color;
        margin-top: 24px;
      }
    }
    .exchange-footer {
      margin-top: 88px;
      .submit-btn {
        min-width: 138px;
        margin: 24px 0 0 auto;
      }
      .no-explain {
        font-size: 12px;
        margin-top: 10px;
        color: @error-color;
      }
    }
  }
}
</style>

