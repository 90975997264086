<template>
  <a-modal
    wrap-class-name="apply-modal"
    :visible="visible"
    :title="exchangeInfo.lockRateType === '40' ? $t('pages_exchange_070') : $t('pages_exchange_034')"
    :mask-closable="false"
    :confirm-loading="loading"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <div v-if="exchangeInfo.lockRateType !== '40'" class="exchange-info">
      <div class="sell-info">
        <div class="title">{{ $t('pages_exchange_003') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_035') }}</span>
          <div class="area-right">
            <img :src="getCountryLogo(exchangeInfo.sellCurrency)">
            <span>{{ exchangeInfo.sellCurrency }}</span>
          </div>
        </div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_036') }}</span>
          <div class="area-right">{{ parseMoney(exchangeInfo.sellAmount, exchangeInfo.sellCurrency) }}</div>
        </div>
      </div>
      <div class="buy-info">
        <div class="title">{{ $t('pages_exchange_002') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_035') }}</span>
          <div class="area-right">
            <img :src="getCountryLogo(exchangeInfo.buyCurrency)">
            <span>{{ exchangeInfo.buyCurrency }}</span>
          </div>
        </div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_036') }}</span>
          <div class="area-right">{{ parseMoney(exchangeInfo.buyAmount, exchangeInfo.buyCurrency) }}</div>
        </div>
      </div>
      <div class="rate-info" style="border-bottom: 0; margin-bottom: 0">
        <div class="title">{{ $t('pages_exchange_037') }}</div>
        <div class="area flex-align">
          <span class="area-left">{{ $t('pages_exchange_038') }}</span>
          <div class="area-right">{{ exchangeInfo.rate }}</div>
        </div>
      </div>
      <div v-if="isTip" class="tip">{{ $t('pages_exchange_083', [exchangeInfo.sellCurrency]) }}</div>
    </div>

    <div v-else class="entrust-info">
      <div class="info">
        <span>{{ $t('pages_exchange_047') }}</span>
        <div class="flex-align" style="margin-left: auto">
          <img :src="getCountryLogo(exchangeInfo.sellCurrency)">
          <span>{{ exchangeInfo.sellCurrency }}</span>
        </div>
      </div>
      <div class="info">
        <span>{{ $t('pages_exchange_048') }}</span>
        <div class="flex-align" style="margin-left: auto">
          <img :src="getCountryLogo(exchangeInfo.buyCurrency)">
          <span style="margin-left: auto">{{ exchangeInfo.buyCurrency }}</span>
        </div>
      </div>
      <div class="info">
        <span>{{ $t('pages_exchange_071') }}</span>
        <span style="margin-left: auto">1{{ arithmetic === 'DIV' ? exchangeInfo.buyCurrency : exchangeInfo.sellCurrency }} = {{ exchangeInfo.entrustRate }}{{ arithmetic === 'DIV' ? exchangeInfo.sellCurrency : exchangeInfo.buyCurrency }}</span>
      </div>
      <div class="info">
        <span>{{ $t('pages_exchange_003') }}</span>
        <span style="margin-left: auto">{{ exchangeInfo.sellAmount }}{{ exchangeInfo.sellCurrency }}</span>
      </div>
      <div class="info">
        <span>{{ $t('pages_exchange_002') }}</span>
        <span style="margin-left: auto">{{ exchangeInfo.buyAmount }} {{ exchangeInfo.buyCurrency }}</span>
      </div>
      <div class="info">
        <span>{{ $t('pages_exchange_073') }}</span>
        <span style="margin-left: auto">{{ exchangeInfo.deadLineDate }} 23:59:59</span>
      </div>
      <div class="tip">{{ $t('pages_exchange_074', [themeData.saasMerNameZh]) }}</div>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs, h } from 'vue'
import { useRouter } from 'vue-router'
import { getCountryLogo, parseMoney } from '@/util'
import { notification, Button } from 'ant-design-vue'
import { _exchange } from '@/api'
import i18n from '@/locale'
export default {
  name: 'ExchangeApply',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    isTip: {
      type: Boolean,
      default: () => { return false }
    },
    arithmetic: {
      type: String,
      default: () => { return '' }
    },
    exchangeInfo: {
      type: Object,
      default: () => { return {} }
    },
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup(props, ctx) {
    const router = useRouter()
    const state = reactive({
      loading: false
    })

    const handleSubmit = async () => {
      state.loading = true
      const { exchangeInfo } = props
      const data = Object.assign({}, exchangeInfo, {})
      if (exchangeInfo.lockRateType === '40') {
        delete data.rate
        delete data.lockRateType
        await entrustApply(data)
      } else {
        delete data.entrustRate
        delete data.deadLineDate
        await normalApply(data)
      }
      state.loading = false
    }

    const normalApply = async (data) => {
      const { exchangeInfo } = props
      const res = await _exchange.exchangeApply(data)
      if (res.data.succ) {
        let message = i18n.global.t('pages_exchange_039')
        if (exchangeInfo.lockRateType === '10') message = i18n.global.t('pages_exchange_040')
        if (exchangeInfo.lockRateType === '20') message = i18n.global.t('pages_exchange_041')
        if (exchangeInfo.lockRateType === '30') message = i18n.global.t('pages_exchange_042')
        dealFinal(true, message)
      } else {
        dealFinal(false, res.data.resMsg)
      }
    }

    const entrustApply = async (data) => {
      const res = await _exchange.entrustExchange(data)
      if (res.data.succ) dealFinal(true, i18n.global.t('pages_exchange_079'))
      else dealFinal(false, res.data.resMsg)
    }

    const dealFinal = (succ, message) => {
      if (succ) {
        notification.success({
          message,
          description: i18n.global.t('pages_exchange_043'),
          btn: () => {
            return h(Button, {
              type: 'primary',
              size: 'small',
              onClick: () => {
                notification.destroy()
                router.push('/exchange/details')
              }
            }, i18n.global.t('pages_exchange_044'))
          }
        })
        ctx.emit('success')
      } else {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: message
        })
      }
    }

    return {
      parseMoney,
      getCountryLogo,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.apply-modal {
  .exchange-info {
    margin-top: -5px;
    padding: 0 18px;
    .sell-info, .buy-info, .rate-info {
      margin-bottom: 24px;
      border-bottom: 1px dashed #999;
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
      }
      .area {
        padding: 0 12px 18px 12px;
        .area-right {
          margin-left: auto;
          img {
            width: 25px;
            height: 18px;
            margin-right: 5px;
          }
        }
      }
    }
    .tip {
      font-size: 13px;
      margin-top: 24px;
      color: @error-color;
    }
  }
  .entrust-info {
    margin-top: -5px;
    .info {
      display: flex;
      margin-bottom: 18px;
      img {
        width: 25px;
        height: 18px;
        margin-right: 5px;
      }
    }
    .tip {
      font-size: 13px;
      margin-top: 24px;
      color: @error-color;
    }
  }
}
</style>

